<template>
  <header>
    <div class="d-flex justify-content-between flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
      <h5 class="my-0 mr-md-auto font-weight-normal">
        <router-link to="/">Machine Learning Класифікатор</router-link>
      </h5>
      <a class="btn btn-outline-secondary" @click='logout'>Вихід</a>
    </div>
  </header>
</template>

<script>
import Cookies from 'js-cookie';

export default {
  methods: {
    logout() {
      Cookies.remove('cdd_token')
      window.location.href = '/login';
    }
  }
};
</script>
