<template>
    <div class="row">
      <div class="col-md-12">
        <div class="login-container">
          <h2>Авторизація</h2>
          <form @submit.prevent="login">
            <div class="form-group mt-3">
              <label for="username">Логін:</label>
              <input type="text" class="form-control" v-model="username" />
            </div>
            <div class="form-group mt-3">
              <label for="password">Пароль:</label>
              <input type="password" class="form-control" v-model="password" />
            </div>
            <button type="submit" class="btn btn-primary mt-3 btn-login">Вхід</button>
          </form>
          <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
        </div>
      </div>
    </div>
</template>

<script>
import router from '@/router'
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    async login() {
      if (this.username == '' || this.password == '') {
        this.errorMessage = 'Please fill in all fields';
        return;
      }

      await axios.post('/rest/users/auth', new URLSearchParams({
          'username': this.username,
          'password': this.password
        }), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        })
        .then(response => {
          const token = response.data.access_token;
          if (token) {
            Cookies.set('cdd_token', token, { path: '/' });
            router.push({ name: 'Index' })
          }
        })
        .catch(error => {
          if (error.status === 401) {
            this.errorMessage = error.response.data.detail;
          } else {
            this.errorMessage = error.message;
          }
        });
    },
  },
};
</script>

<style scoped>
html {
    background-color: #f8f9fa;
}
.login-container {
    max-width: 600px;
    margin: 20vh auto;
    padding: 40px 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.login-container h2 {
    text-align: center;
    margin-bottom: 30px;
}
.form-group {
    margin-bottom: 20px;
}
.form-group label {
    font-weight: bold;
}
.btn-login {
    background-color: #007bff;
    border: none;
}
.btn-login:hover {
    background-color: #0056b3;
}
</style>
