import 'bootstrap/dist/css/bootstrap.css';
import { createApp } from 'vue'
import axios from 'axios';

import App from './App.vue'
import router from './router'

const app = createApp(App);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
app.config.globalProperties.$axios = axios;

app.use(router);
app.mount("#app");
