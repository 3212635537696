<template>
    <div class="loader-container" :style="{ display: loading ? 'flex' : 'none' }">
      <div class="loader"></div>
      <p class="mt-3">Processing...</p>
    </div>

    <h3 class="text-center">Оберіть варіант класифікації</h3>

    <div class="box mt-5">
      <div class="row">
        <!-- Predict One or Many -->
        <div class="col-sm-6 mb-2">
          <div class="card h-100">
            <form @submit.prevent="classifyOnScreen" class="card-body d-flex flex-column justify-content-between">
              <div>
                <h5 class="card-title mb-3">Один чи декілька</h5>
                <textarea v-model="descriptions" class="form-control" rows="12" placeholder="Введіть опис СКЮ сюди. Можна використовувати багато рядків, щоб опрацювати декілька SKU одночасно"></textarea>
              </div>
              <button class="btn btn-primary w-100 mt-3" type="submit">Класифікувати</button>
            </form>
          </div>
        </div>

        <!-- Process File -->
        <div class="col-sm-6 mb-2">
          <div class="card h-100">
            <form @submit.prevent="classifyFile" class="card-body d-flex flex-column justify-content-between">
              <div>
                <h5 class="card-title mb-3">Класифікація в файлі</h5>
                <input type="file" @change="handleFileUpload" accept=".csv, .xlsx" class="form-control" />
                <label class="mt-2">
                  <p>Можна завантажувати дані тільки в CSV чи Excel форматах. Всі описи для класифікації мають бути в першій колонці (A).</p>
                  <p>
                    Шаблон файлу для завантаження доступний за <a href="/examples/classificator import template.csv">посиланням</a>.
                  </p>
                </label>
              </div>
              <div>{{ successMessage }}</div>
              <button class="btn btn-primary w-100 mt-3" type="submit">Класифікувати</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Error message -->
    <div v-if="errorMessage" class="alert alert-danger mt-4 text-center">{{ errorMessage }}</div>

    <!-- Predictions table -->
    <div v-if="predictions.length" class="mt-4">
      <table class="table table-striped">
        <thead>
            <th>Запит</th>
            <th>Категорія</th>
            <th>Підкатегорія</th>
            <th>Тип</th>
            <th>ТМ</th>
            <th>Фасований</th>
            <th>Вага</th>
            <th>Група</th>
            <th>Пакування</th>
            <th>Температурні умови</th>
            <th>Аналітична група</th>
        </thead>
        <tbody>
            <tr v-for="prediction in predictions" :key="prediction.id">
                <td>{{ prediction.searched_description }}</td>
                <td>{{ prediction.category }}</td>
                <td>{{ prediction.subcategory }}</td>
                <td>{{ prediction.type }}</td>
                <td>{{ prediction.brand }}</td>
                <td>{{ prediction.packaging == 1 ? 'так' : 'ні' }}</td>
                <td>{{ (prediction.weight_value > 0 ? prediction.weight_value : '')  + prediction.weight_unit }}</td>
                <td>{{ prediction.group }}</td>
                <td>{{ prediction.package_type }}</td>
                <td>{{ prediction.temperature_condition }}</td>
                <td>{{ prediction.analytic_group }}</td>
            </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import apiClient from '../axiosSetup.js';

export default {
  data() {
    return {
      descriptions: '',
      predictions: [],
      errorMessage: '',
      successMessage: '',
      file: null,
      loading: false,
    };
  },
  methods: {
    classifyOnScreen() {
        this.resetFields();

        if (this.descriptions === '') {
            this.errorMessage = 'Data can not be empty';
            return;
        }

        let descriptionsJson = this.convertTextAreaToJson(this.descriptions);

        this.predict(descriptionsJson)
        .then((items) => {
            if (typeof items === 'undefined') return;
            this.predictions = items;
        });
    },
    async predict(descriptionsJson) {
      this.loading = true;

      try {
        const response = await apiClient.post('/rest/sku', descriptionsJson, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.loading = false;

        if (response.data.total > 0) {
          return response.data.items;
        }
      } catch (error) {
        this.loading = false;
        this.errorMessage = 'An unexpected error occurred.';

        if (error.response) {
          switch (error.response.status) {
            case 401:
              this.errorMessage = 'Please login first';
              break;
            case 409:
              this.errorMessage = 'Model is not trained yet. Try again or contact the support team, please';
              break;
            case 424:
              this.errorMessage = 'Failed connection to data-dictionary API';
              break;
            default:
              this.errorMessage = error.response.statusText;
          }
        }
      }
    },
    convertTextAreaToJson(textAreaContent) {
      const lines = textAreaContent.split('\n');
      let jsonArray = [];

      lines.forEach(function(line) {
          line = line.trim();

          if  (line.length > 0 && (typeof line === 'string' || line instanceof String)) {
              let jsonObject = {};
              jsonObject['description'] = line;
              jsonArray.push(jsonObject);
          }
      });
      return JSON.stringify(jsonArray);
    },
    async classifyFile() {
      this.resetFields();

      if (this.file == null || this.file.name === '') {
        this.errorMessage = 'No file was selected';
        return;
      }

      const fileSizeLimitMB = 16;
      if (this.file.size > 1 * 1024 * 1024) {
          this.errorMessage = 'The file is too big. File size is limited by ' + fileSizeLimitMB + 'MB';
          return;
      }

      this.loading = true;

      // Create FormData to send the file
      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const response = await apiClient.post('/rest/sku/file', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        // Handle successful upload
        this.successMessage = 'File uploaded successfully. Processed results will be downloaded automatically.';
        this.downloadFile(response.data);
      } catch (error) {
            // Handle error response
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.errorMessage = 'Please login first';
                  break;
                case 400:
                  this.errorMessage = 'Check if all values are valid in the uploaded file';
                  break;
                case 409:
                  this.errorMessage = 'Model is not trained yet. Try again or contact support team, please';
                  break;
                case 415:
                  this.errorMessage = 'Wrong CSV file format - only CSV or Excel are supported';
                  break;
                case 422:
                  this.errorMessage = 'Wrong CSV file encoding - please use UTF-8 or ASCII during saving the file.';
                  break;
                default:
                  this.errorMessage = error.response.statusText;
                  break;
              }
            } else {
              this.errorMessage = 'File upload failed';
            }
      } finally {
        this.loading = false;
      }
    },
    downloadFile(blobData) {
      const fileExtension = this.file.name.split('.').pop();
      const newFileType = fileExtension === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const newFile = new Blob([blobData], { type: newFileType });
      const fileURL = URL.createObjectURL(newFile);

      // Create a dynamic link to download the file
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = 'classification_result';
      fileLink.click();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    resetFields() {
      this.errorMessage = '';
      this.predictions = [];
      this.resultFileUrl = '';
    }
  },
};
</script>
