<template>
  <div>
    <header-component v-if="!$route.meta.hideHeader"></header-component>

    <!-- Main Content -->
    <main class="container mt-4">
      <router-view />
    </main>

    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import FooterComponent from './FooterComponent.vue';

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style>
html {
  font-size: 14px;
  background-color: #F1F1F1
}

body {
    background-color: inherit;
}

a {
    text-decoration: none;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.container {
  margin-top: 50px;
  max-width: 1280px;
}

.pricing-header {
  max-width: 700px;
}

.card-deck .card {
  min-width: 220px;
}

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.box {
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
    color: #4a4a4a;
    display: block;
    padding: 1.25rem;
}

/* styles for instant-search-list */
.instant-search {
  position: relative;
  display: inline-block;
  width: 700px;
}

.instant-search-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
}

.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
