import { createRouter, createWebHistory } from 'vue-router'
import BaseLayout from '@/components/BaseComponent.vue'
import LoginView from '@/views/LoginView.vue'
import IndexView from '@/views/IndexView.vue'
import { getTokenFromCookie } from '@/utils/auth';

const routes = [
  {
    path: '/',
    component: BaseLayout,
    children: [
        {
            path: '',
            name: 'Index',
            component: IndexView,
            meta: { requiresAuth: true },
        },
        {
            path: '/login',
            name: 'Login',
            component: LoginView,
            meta: { hideHeader: true },
        }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token = getTokenFromCookie();

  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ name: 'Login' });
  } else if (to.matched.some(record => record.name === 'Login') && token) {
    next({ name: 'Index' });
  } else {
    next();
  }
});

export default router
